import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { CtyButton } from "../button/CtyButton";
import SvgViewerContainer from "./svgViewer";

function FileViewer() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const tabKey = queryParams.get('tabKey');
    const isExtension = window.location.protocol === 'chrome-extension:' || window.location.protocol === 'moz-extension:';
    const pdfData = (() => {
        if (isExtension) {
            const dataParam = queryParams.get('data');
            return dataParam ? JSON.parse(dataParam) : {};
        } else {
            const storedData = sessionStorage.getItem(`pdfData-${tabKey}`);
            return storedData ? JSON.parse(storedData) : {};
        }
    })();
    const fileType = pdfData.fileType ?? 'pdf';
    const fileName = pdfData.fileName ?? '';
    const [isFullPdfOpen, setIsFullPdfOpen] = useState(false);
    const currentFileUrl = useRef(pdfData.initialPdfUrl ?? pdfData.fullPdfUrl);
    useEffect(() => {
        currentFileUrl.current = pdfData.initialPdfUrl ?? pdfData.fullPdfUrl;
    }, [pdfData]);

    const handlePdfChanger = () => {
        if (isFullPdfOpen) {
            currentFileUrl.current = pdfData.initialPdfUrl;
        } else {
            currentFileUrl.current = pdfData.fullPdfUrl;
        }
        setIsFullPdfOpen(prev => !prev);
    }
    const renderFileViewer = () => {
        switch (fileType) {
            case 'pdf':
                return (
                    <div className="h-full w-full">
                        <div className={`w-full  px-3 py-2`}>
                            {(pdfData.initialPdfUrl !== pdfData.fullPdfUrl) && <CtyButton size="md" id="pdf-file-name" color="primary"  disableAnimation={true} disableRipple={true} className="border-radius-0 bg-[white] font-medium text-base text-black"  onPress={handlePdfChanger}>{isFullPdfOpen ? `View Single Page of ${fileName}` : `View Entire ${fileName}`}</CtyButton>}
                            {(pdfData.initialPdfUrl === pdfData.fullPdfUrl) && <p className="border-radius-0 bg-[white] font-medium text-base text-black">{fileName}</p>}
                        </div>
                        <iframe src={currentFileUrl.current} className="w-full h-[100vh]" title="pdf"></iframe>
                    </div>
                );
            case 'svg':
                return (
                    <div className="h-full w-full bg-[#fff]">
                        <SvgViewerContainer svgUrl={currentFileUrl.current} sourceIdData={pdfData?.responseSourceData || []} svgType='schematicsvg' />

                    </div>
                );
            case 'parts_svg':
                return (
                    <div className="h-full w-full bg-[#fff]">
                        <SvgViewerContainer svgUrl={currentFileUrl.current} sourceIdData={pdfData?.responseSourceData || []} svgType='partssvg' />

                    </div>
                );
            case 'html':
            case 'htm':
            case 'xml':
            case 'url':
            case 'xlsx':
            case 'xls':
            case 'docx':
            case 'mp4':
            case 'mp3':
            case 'csv':
                window.open(currentFileUrl.current, '_blank');
                return null;

            default:
                window.open(currentFileUrl.current, '_blank');
                return null;
        }
    };

    return (
        <div className="h-full w-full">
            {renderFileViewer()}
        </div>
    );
}

export { FileViewer };
