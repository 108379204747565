import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import * as d3 from 'd3';
import { useGetPdfLinkForConversationsMutation } from '@circuitry-ai/doc-data';
import { useParams } from 'react-router-dom';
import CtySpinner from '../spinner/CtySpinner';
import { CtyButton } from '../button/CtyButton';
interface PartsSvgViewerProps {
  svgUrl: string;
  sourceIdData: any;
  currentActivePartsRowData?: any;
  setSelectedRowId: any;
}


// Loading spinner component
const LoadingSpinner = ({ loadingText }: { loadingText: string; }) => (
  <div className="flex justify-center items-center min-h-[600px]">
    <CtySpinner size="md" color="primary" />
  </div>
);
const PartsSvgViewer: React.FC<PartsSvgViewerProps> = ({ svgUrl, sourceIdData, currentActivePartsRowData, setSelectedRowId }) => {
  const partsList = useMemo(() => {
    return sourceIdData[0]?.element_refs[0]?.text || [];
  }, [sourceIdData]);
  const svgContainerRef = useRef<HTMLDivElement>(null);
  const svgRef = useRef<SVGSVGElement | null>(null);
  const [tooltipData, setTooltipData] = useState<PartInfo | null>(null);
  const [tooltipPosition, setTooltipPosition] = useState<{ x: number; y: number; } | null>(null);
  const [svgLoadError, setSvgLoadError] = useState<string | null>(null);
  const fileName = useParams<{ fileName: string; }>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const containerRect = svgContainerRef.current?.getBoundingClientRect();

  const activeReferenceNumber =
    currentActivePartsRowData?.[1]?.props?.children || null;
  // Function to initialize or update parts highlighting
  const updatePartsHighlighting = useCallback(() => {
    if (!svgRef.current) return;

    const svgElement = d3.select(svgRef.current);
    const textElements = svgElement.selectAll('text');

    const handleTextClick = (textElement: d3.Selection<SVGTextElement, unknown, null, undefined>, part, isTableRowClicked) => {
      const bbox = textElement.node()?.getBoundingClientRect();
      const toolTipData = {
        parts_number: isTableRowClicked ? currentActivePartsRowData?.[2]?.props?.children : part?.part_info?.parts_number ?? '',
        description: isTableRowClicked ? currentActivePartsRowData?.[3]?.props?.children : part?.part_info?.description ?? '',
      };
      // Highlight the corresponding table row
      if (!isTableRowClicked) {
        const tableRowId = part.reference_number + '-' + part.part_info.parts_number + "-" + part.part_info.description
        setSelectedRowId && setSelectedRowId(tableRowId);
      }
      setTooltipData(toolTipData);
      setTooltipPosition({
        x: bbox?.x + window.scrollX + (bbox?.width || 0) / 2,
        y: bbox?.y + window.scrollY - 20,
      });
    };

    textElements.each(function () {
      const textElement = d3.select(this);
      const textContent = textElement.text().trim();
      const part = partsList.find((p) => p.reference_number === textContent);

      if (part) {

        textElement
          .classed('highlighted', true)
          .on('click', () => handleTextClick(textElement, part, false));
      }
    });

    // Trigger text element click when `currentActivePartsRowData` changes
    if (activeReferenceNumber) {
      const targetTextElement = textElements.filter((_, i, nodes) =>
        d3.select(nodes[i]).text().trim() === activeReferenceNumber
      );
      const part = partsList.find((p) => p.reference_number === activeReferenceNumber);
      handleTextClick(d3.select(targetTextElement.node()), part, true);

    }
  }, [partsList, activeReferenceNumber, currentActivePartsRowData]);

  // Load the SVG when the component mounts
  useEffect(() => {
    const loadSvg = async () => {
      setIsLoading(true);
      setSvgLoadError(null);
      if (!svgUrl) {
        setSvgLoadError('No SVG URL provided');
        setIsLoading(false);
        return;
      }

      try {
        // Fetch the SVG as text
        const response = await fetch(svgUrl);
        if (!response.ok) {
          throw new Error(`Failed to fetch SVG: ${response.statusText}`);
        }
        const svgText = await response.text();

        // Parse the SVG text into a DOM element
        const parser = new DOMParser();
        const svgDocument = parser.parseFromString(svgText, 'image/svg+xml');
        const svgElement = svgDocument.documentElement;

        // Clear the container and append the SVG element
        if (svgContainerRef.current) {
          svgContainerRef.current.innerHTML = '';
          svgContainerRef.current.appendChild(svgElement);
        }

        // Update the reference for further D3 manipulation
        svgRef.current = svgElement;

        // Initialize highlighting
        updatePartsHighlighting();
      } catch (error) {
        console.error('Error loading SVG:', error);
        setSvgLoadError(error instanceof Error ? error.message : 'Unknown error loading SVG');
      } finally {
        setIsLoading(false);
      }
    };

    loadSvg();
  }, [svgUrl]);

  // Update highlighting whenever `currentActivePartsRowData` changes
  useEffect(() => {
    updatePartsHighlighting();
  }, [currentActivePartsRowData, updatePartsHighlighting]);
  const renderSvgAndTooltip = () => {
    return (
      <>
        <div ref={svgContainerRef} style={{ minWidth: '1000px' }}></div>

        {/* Tooltip */}
        {tooltipData && tooltipPosition && (
          <div
            style={{
              position: 'absolute',
              left: `${isNaN(tooltipPosition.x) || tooltipPosition.x === undefined
                ? 300
                : tooltipPosition.x + 20 - (!fileName.fileName ? containerRect?.left ?? 0 : 0)}px`,
              top: `${isNaN(tooltipPosition.y) || tooltipPosition.y === undefined
                ? 100
                : tooltipPosition.y + 15 - (!fileName.fileName ? containerRect?.top ?? 0 : 0)}px`,

              backgroundColor: '#003A79',
              color: '#fff',
              padding: '8px',
              borderRadius: '4px',
              transform: 'translate(-50%, -100%)',
              boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
              whiteSpace: 'normal', // Allow text to wrap
              wordBreak: 'break-word', // Break long words if needed
              maxWidth: '200px', // Limit the width of the tooltip
            }}
          >
            <CtyButton

              className='absolute top-1 right-1 cursor-pointer text-white text-xs p-1 rounded-full min-w-0 bg-transparent'
              onPress={() => setTooltipData(null)} // Assuming setShowTooltip is a state handler
            >
              ✕
            </CtyButton>
            <div>

              <strong>Part Number:</strong> {tooltipData.parts_number}
            </div>
            <div>
              <strong>Description:</strong> {tooltipData.description}
            </div>
            {/* Downward Pointer */}
            <div
              style={{
                position: 'absolute',
                bottom: '-10px',
                left: '40%',
                transform: 'translateX(-50%)',
                width: '0',
                height: '0',
                borderLeft: '10px solid transparent',
                borderRight: '10px solid transparent',
                borderTop: '10px solid #003A79', // Matches tooltip background
              }}
            />
          </div>
        )}

      </>
    );
  };

  return (
    <div className="flex flex-col h-full min-h-[600px] w-full ">
      {fileName.fileName && (
        <div className="flex flex-wrap w-full gap-10 items-center justify-center p-4 border-b-1 border-grey">
          <div className="w-full">
            <h3 className="font-bold text-center break-words">{fileName?.fileName}</h3>
          </div>
        </div>
      )}
      {isLoading && <LoadingSpinner text="Preparing Svg...." />}
      {fileName.fileName ? <div className='flex lg:justify-center'>
        {renderSvgAndTooltip()}
      </div> : renderSvgAndTooltip()}
    </div>
  );
};


interface PartInfo {
  parts_number: string;
  description: string;
}

interface PartSvgViewerInlineProps {
  message: any;
  advisorId: string;
  currentActivePartsRowData: any;
  setSelectedRowId: any;
}
const PartSvgViewerInline: React.FC<PartSvgViewerInlineProps> = ({ message, advisorId, currentActivePartsRowData, setSelectedRowId }) => {
  const [elementRefs, setElementRefs] = useState<any[]>(() => ([{ source_id: message?.metaData?.[1]?.source_ids[0].source_id, page_labels: [] }])); // Initialize with empty array
  const [getPdfLink, { error, isError }] = useGetPdfLinkForConversationsMutation();
  const [svgUrl, setSvgUrl] = useState<string>('');
  const [isPartsDataLoading, setIsPartsDataLoading] = useState(false);
  const sourceIdData = message?.metaData?.[1]?.source_ids;

  // Fetch SVG URL
  useEffect(() => {
    if (elementRefs.length > 0) {
      (async () => {
        try {
          setIsPartsDataLoading(true);
          const getPdfLinkRequestPayload = {
            advisor_id: advisorId,
            source_ids: elementRefs,
          };

          const urlResponse = await getPdfLink(getPdfLinkRequestPayload).unwrap();
          setSvgUrl(urlResponse?.data?.[0]?.full_source_presigned_url || '');
        } catch (e) {
          console.error((e as Error)?.message || 'Error Fetching Image');
        } finally {
          setIsPartsDataLoading(false);
        }
      })();
    }
  }, [elementRefs, advisorId, getPdfLink]);

  return (
    <div className="w-full relative min-w-[800px] ">
      {isError ? (
        <div className="text-red-500 flex justify-center items-center h-[600px]">
          {/* Display error message if available, or a default fallback */}
          {error?.message || "Failed to load SVG, please try again later."}
        </div>
      ) : isPartsDataLoading ? (
        <LoadingSpinner text={"Fetching Svg ...."} />
      ) : (
        <PartsSvgViewer svgUrl={svgUrl} sourceIdData={sourceIdData} currentActivePartsRowData={currentActivePartsRowData} setSelectedRowId={setSelectedRowId} />
      )}
    </div>

  );
};



export { PartSvgViewerInline, PartsSvgViewer };

