import { Avatar, Listbox, ListboxItem, Popover, PopoverContent, PopoverTrigger, Tooltip } from '@nextui-org/react';
import { Icon } from '@iconify/react';
import { RootState, useGetUserOrgsQuery } from '@circuitry-ai/doc-data';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { getUserInitials } from '../utils/commonutils';
import { CtyButton } from '../button/CtyButton';

interface SwitchOrgsProps {
    isCollapsed: boolean;
}

interface OrgData {
    display_name: string,
    id: string,
    metadata: {
        cloud_setup_status: string,
        tenant_id: string;
    },
    name: string;
}

const SwitchOrgs = (props: SwitchOrgsProps) => {

    const { isCollapsed } = props;
    const { loginWithRedirect, logout } = useAuth0();
    const { details: user } = useSelector((state: RootState) => state.auth.user);
    const { data: userOrgsList, error: isUserOrgsError, isLoading: isUserOrgsLoading } = useGetUserOrgsQuery(user?.sub, { skip: !user?.sub });

    if ( isUserOrgsLoading) return;
    const userInitial = getUserInitials(user?.name);

    const handleOrganisationSwitch = (name: string) => {
        loginWithRedirect({
            authorizationParams: {
                organization: name
            }
        });
    };

    const doLogout = () => {
        logout({
            logoutParams: { returnTo: window.location.origin },
        });
    };

    return (
        <div className="border-t-2  py-2 cursor-pointer">

            <Popover placement="right">
                <PopoverTrigger>
                    {isCollapsed ?
                        <div className='p-3 flex items-center justify-center mr-3'>
                            <Avatar
                                size={'md'}
                                showFallback
                                src={user?.picture}
                                name={userInitial}
                                alt="Profile"
                                classNames={{ base: 'bg-[#f0f1f2] text-[#475467]' }}
                            />
                        </div>
                        :
                        <div className='flex flex-row items-center justify-between bg-rsed-300 w-[270px] p-3'>
                            <Tooltip content={'Switch Organization'} color="warning" showArrow>
                                <div className='flex flex-row gap-3 items-center'>
                                    <Avatar
                                        size={'md'}
                                        showFallback
                                        src={user?.picture}
                                        name={userInitial}
                                        alt="Profile"
                                        classNames={{ base: 'bg-[#f0f1f2] text-[#475467]' }}
                                    />
                                    <div>
                                        <div className='font-poppins text-sm font-medium leading-5 text-left'>{user?.name}</div>
                                        <div className="font-poppins text-sm font-normal leading-5 text-left">{user?.org_name}</div>
                                    </div>
                                </div>
                            </Tooltip>
                            <CtyButton
                                tooltipContent={'Logout'}
                                toolTipColor='warning'
                                id={`user-logout`}
                                isIconOnly
                                radius="full"
                                size="sm"
                                variant="light"
                                onPress={doLogout}
                            >
                                <Icon icon="ion:log-out-outline" width="24" height="24" style={{ color: "#667085" }} />
                            </CtyButton>
                        </div>
                    }
                </PopoverTrigger>
                <PopoverContent className='w-[240px] border-0 '>
                    <Listbox aria-label="user-orgs" onAction={(selected) => handleOrganisationSwitch(selected.toString())}>
                        {(userOrgsList ?? []).map((org: OrgData, index: number) => {
                            const initials = getUserInitials(org.display_name);
                            return <ListboxItem key={org.name} showDivider title={org.display_name} value={org.display_name} endContent={<Icon icon="mynaui:refresh" width="18" height="18" style={{ color: "#667085" }} />} startContent={<Avatar
                                size={'md'}
                                showFallback
                                name={initials}
                                alt="Profile"
                                classNames={{ base: 'bg-[#f0f1f2] text-[#475467]' }}
                            />} />;
                        })}
                    </Listbox>
                </PopoverContent>
            </Popover>
        </div>
    );
};

export { SwitchOrgs };